// mapモジュールの読込
@use "sass:map";

// ブレイクポイント
// bootstrapのものを仕様
$site-breakpoint-sp: map.get($grid-breakpoints, "md");

// bsのガター幅※bsに合わせて0.5倍？
$site-grid-gutter-width : calc($grid-gutter-width * 0.5);

// ベースのカラー
// 黒
$site-bese-color-black: $body-color;

// キーカラー
$site-key-color: rgb(145, 23, 40);

// サイトヘッダー高さ
// スマートフォン
$site-header-height-sp: 50px;

// パソコン
$site-header-height-pc: 65px;

// z-index
// ヘッダー
$site-zindex-header: 100;

// メガメニュー
$site-zindex-megamenu: -1;