// Bootstrap の変数を使用するため、インポート
// ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
// scss-docs-start import-stack
// Configuration
@import "~bootstrap/scss/functions";
// ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑
// Bootstrap の変数を使用するため、インポート

// カスタマイズしたBootstrapの変数
@import "./_bootstrap_custom_variables.scss";

// Bootstrap の変数を使用するため、インポート
// ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
@import "~bootstrap/scss/variables";
// ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑
// Bootstrap の変数を使用するため、インポート

// サイト用の変数
@import "./_site_variables.scss";

// サイト用のmixin
@import "./_site_mixin.scss";

// ---------------------------------------------------------------------------

// テストコード
// HTML+CSSから最終的に削除すること
// 親いっぱいまで広がる画像
// img.demo-image-setting-001 {
//     width: 100% !important;
//     height: 100% !important;
//     background-color: aqua !important;
// }

.clearfix::after {
    content: "";
    display: block;
    clear: both;
}

.scroll {
    overflow: auto; /* 横スクロールバーを表示 */
    white-space: nowrap; /* セル内にあるテキストの折り返しを禁止 */
    margin: 30px 0;
}

.tableBasic {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #bcbcbc;

    th,
    td {
        padding: .75rem 1rem;
        border: 1px solid #bcbcbc;
        font-weight: bold;
    }

    th {
        background-color: #edeeed;
        border: 1px solid #bcbcbc;
        text-shadow: 1px 1px 0 #fff;
    }

    .tableBasic-list {
        padding-left: 1rem;

    }
}

.tableBasic-list {
    
    dd {
        background-color: #f0feff;
        padding: 10px;
    }

}

  .tableLine {
    width: 100%;
    margin: 0 auto;

    tr{
        &:nth-last-of-type(1) {
            border-bottom: 1px solid rgba($site-bese-color-black, 50%);
          }
    }

    th,
    td {
        display: block;
        width: 100%;
        padding: 10px 0;
       

        @include media-pc {
            display: table-cell;
            margin-top: 15px;
            padding: 20px 0;
        }
    }

    th {
        
        color: $site-key-color;
        border-top: 1px solid rgba($site-bese-color-black, 50%);

        @include media-pc {
            width: 19%;
        }
    }

    td {
        border-top: none;

        @include media-pc {
            border-top: 1px solid rgba($site-bese-color-black, 50%);
        }
    }
}


.resposiveImg {
    display: block;
    width: 100%;
    height: auto;
    max-width: 500px;
    margin: 0 auto;

    @include media-pc {
        display: unset;
        width: unset;
        height: unset;
        max-height: unset;
    }
}

.resposiveImg_2 {
    width: 100%;
    height: auto;
    max-width: 100%;
    margin: 0 auto;

    @include media-pc {
        display: unset;
        width: unset;
        height: unset;
        max-height: unset;
    }
}

// .resposiveImg_recruit {
//     display: block;
//     width: 100%;
//     height: auto;
//     max-width: 730px;
//     margin: 0 auto;

//     @include media-pc {
//         display: unset;
//         width: unset;
//         height: unset;
//         max-height: unset;
//     }
// }

.text-keyColor {
    font-size: 1.1rem;
    color: $site-key-color;
}

ol {
    li>ol {
        list-style: lower-roman;
    }
}

.basicH {
    padding: 1rem;
    color: #fff;
    background: $site-key-color;
    // -webkit-box-shadow: 5px 5px 0 lighten($site-key-color, 20%);
    box-shadow: 5px 5px 0 lighten($site-key-color, 40%);
}

.basicH2 {
    position: relative;
	display: table-cell;
	padding-left: 55px;
    font-size: 1.3rem;
    font-weight: bold;

    @include media-pc {
        font-size: 1.4rem;
    }

    &::before {
        position: absolute;
        content: '';
        top: calc(50% - 5px);
        width: 40px;
        left: 0;
        height: 6px;
        border-top: solid 6px $site-key-color;
    }
}

.basicH3 {
    position: relative;
    padding: 1rem;
    box-shadow: 5px 5px $site-key-color;
    border: 2px solid $site-key-color;
    color: $site-key-color;
    font-weight: bold;
    text-align: center;

    &:before {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        height: 10px;
        width: 20px;
        background-color: #fff;
        content: "";
    }

    &:after {
        position: absolute;
        top: 95%;
        left: 50%;
        transform: translateX(-50%);
        height: 20px;
        width: 2px;
        background-color: $site-key-color;
        content: "";
    }

}

.basicH4 {
    padding-left: 55px;
    position: relative;

    &:before {
        content: "";
        background-color: #f00;
        border-radius: 50%;
        opacity: 0.5;
        width: 35px;
        height: 35px;
        left: 5px;
        top: 0px;
        position: absolute;
    }

    &:after {
        content: "";
        background-color: #f00;
        border-radius: 50%;
        opacity: 0.5;
        width: 20px;
        height: 20px;
        left: 25px;
        top:15px;
        position: absolute;
    }
}

// SPメニュー展開時、windowスクロールバー非表示
body.spMenuOpen {
    overflow: hidden;

    @include media-pc {
        overflow: unset;
    }
}

body,
html {
    // i-phoneSE相当
    min-width: 375px;
    word-break: break-all;
}

// ヘッダー
.siteHeader {
    z-index: $site-zindex-header;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    height: $site-header-height-sp;

    @include media-pc {
        height: $site-header-height-pc;
    }
}

.brandMark {
    display: flex;
    width: 160px;
    height: 100%;
    align-items: center;

    @include media-pc {
        width: 280px;
    }

    .brandMark-image {
        display: block;
        width: 100%;
        height: auto;
    }
}

// ハンバーガーメニュー
.hamburgerMenu {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    margin-left: auto;
    border-width: 1px;
    border-style: solid;
    border-color: rgba($site-bese-color-black, .5);
    border-radius: 2px;
    background-color: #fff;
    line-height: 1;
    cursor: pointer;

    @include media-pc {
        display: none;
    }

    &:hover {
        border-color: rgba($site-bese-color-black, .75);
    }

    .hamburgerMenu-icon {
        position: absolute;
        top: calc(50% - 12px);
        left: calc(50% - 12px);
        color: $site-key-color;
        font-size: 24px;
        transition: color .3s;
    }

    &:hover .hamburgerMenu-icon {
        color: rgba($site-key-color, .75);
    }
}

// グローバルナビゲーション
.siteGNav {
    display: none;
    overflow: hidden;
    position: fixed;
    top: $site-header-height-sp;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    justify-content: end;
    background-color: rgba($site-bese-color-black, .0);
    // transition: background-color .3s;

    @include media-pc {
        display: block;
        position: relative;
        top: unset;
        bottom: unset;
        left: unset;
        right: unset;
        display: unset;
        justify-content: unset;
        width: 100%;
        height: 100%;
        background-color: rgba($site-bese-color-black, .0);
        transition: none;
    }

    &.spMenuOpen {
        @keyframes show-siteGNav {
            0% {
                background-color: rgba($site-bese-color-black, .0);
            }

            100% {
                background-color: rgba($site-bese-color-black, .35);
            }
        }

        display: flex;
        animation: show-siteGNav .3s forwards;

        @include media-pc {
            background-color: transparent;
        }
    }

    // リセット
    ul,
    dl,
    dt,
    dd {
        padding: 0;
        margin: 0;
    }

    ul {
        list-style: none;
    }

    // リスト
    .siteGNav-list {
        $siteGNav-list-width : 65%;

        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        left: 100%;
        width: $siteGNav-list-width;
        display: flex;
        height: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: normal;
        background-color: lighten($site-bese-color-black, 10%);
        transition: left .3s;

        @include media-pc {
            overflow-x: unset;
            overflow-y: unset;
            position: unset;
            left: unset;
            width: 100%;
            height: auto;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
            background-color: transparent;
            transition: none;
        }

        &.spMenuOpen {
            @keyframes show-siteGNavList {
                0% {
                    left: 100%;
                }

                100% {
                    left: calc(100% - $siteGNav-list-width);
                }
            }

            animation: show-siteGNavList .3s forwards;

            @include media-pc {
                left: unset;
            }
        }

        // アイテム
        .siteGNav-item {
            border-bottom: 1px solid rgba(#fff, 25%);

            @include media-pc {
                border-bottom: none;
            }

            // アイテム名
            .siteGNav-itemName {
                z-index: 1;
                position: relative;
                display: flex;
                align-items: center;
                padding: .75rem 1.0rem;
                color: #fff;
                font-weight: bold;
                text-decoration: none;
                cursor: pointer;
                transition: background-color .3s, color .3s;

                &:hover,
                &.siteGNav-itemName-active {
                    @include media-pc  {
                        // background-color: lighten($site-key-color, 10%);
                        // color: lighten($site-key-color, 10%);
                        color: lighten($site-key-color, 10%);
                    }
                }

                &.current{
                    color: #fff;

                    &::after {
                        content: "";
                        z-index: -1;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: lighten($site-key-color, 10%);

                        @include media-pc {
                            top: 9px;
                            bottom: 9px;
                        }
                    }
                }

                @include media-pc {
                    height: $site-header-height-pc;
                    color: $site-bese-color-black;
                }

                .siteGNav-itemName-icon {

                    @include media-pc {
                        padding-left: .25rem;
                    }
                }
            }

            // メガメニュー外側
            .siteGNav-megaMenuOuter {

                @include media-pc {
                    // z-index: $site-zindex-megamenu;
                    display: unset;
                    width: 100%;
                    height: 0;
                    position: fixed;
                    top: $site-header-height-pc;
                    left: 0;
                    right: 0;
                }

                // メガメニュー
                .siteGNav-megaMenu {
                    display: none;
                    background-color: rgba(242, 242, 242, 1);

                    @include media-pc {
                        display: block !important;
                        transform: translateY(calc(-80px + -100%));
                        opacity: 0%;
                        transition: transform .3s ease-in, opacity .3s ease-out;

                        // 表示(PC)
                        &.show-siteGNav-megaMenu {
                            transform: translateY(0);
                            opacity: 100%;
                        }
                    }
                }

                // インナー
                .siteGNav-megaMenu-inner {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    padding-left: 0;
                    padding-right: 0;

                    @include media-pc {
                        padding-left: $site-grid-gutter-width;
                        padding-right: $site-grid-gutter-width;
                    }

                    $siteGNav-megaMenu-inner-padding: 1.5rem;

                    // ディスプレイ
                    .siteGNav-megaMenu-display {
                        position: relative;
                        overflow: hidden;
                        min-width: 300px;
                        width: 300px;
                        padding: $siteGNav-megaMenu-inner-padding;

                        &::before {
                            content: "";
                            position: absolute;
                            top: $siteGNav-megaMenu-inner-padding;
                            bottom: $siteGNav-megaMenu-inner-padding;
                            right: 0;
                            display: block;
                            width: 1px;
                            background-color: #fff;
                        }

                        // 画像
                        .siteGNav-megaMenu-image {
                            display: block;
                            width: auto;
                            height: 200px;
                        }
                    }


                    // メイン
                    .siteGNav-megaMenu-main {
                        flex-grow: 1;

                        @include media-pc {
                            padding: $siteGNav-megaMenu-inner-padding;
                        }

                        // カテゴリー
                        .siteGNav-megaMenu-cateGory {
                            text-align: center;
                            display: none;

                            @include media-pc {
                                display: block;
                                padding-top: .75rem;
                                padding-bottom: .75rem;
                                text-align: unset;
                            }

                            // カテゴリー名
                            .siteGNav-megaMenu-cateGoryName {
                                display: block;
                                padding: .5rem;
                                color: $site-bese-color-black;
                                // font-size: 1.2rem;
                                transition: color .3s ease-in-out;
                                text-decoration: none;

                                // &:hover {
                                //     color: $site-key-color;
                                // }

                                @include media-pc {
                                    font-size: 1.4rem;
                                }

                                .siteGNav-itemName-inner {
                                    position: relative;

                                    &::before,
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        left: -13px;
                                        display: block;
                                        width: 2px;
                                        height: 6px;
                                        background-color: $site-key-color;
                                        transition: left .3s ease-in-out;

                                        @include media-pc {
                                            content: none;
                                        }
                                    }

                                    &::before {
                                        top: calc(50% - 3px);
                                        transform: skew(45deg);
                                    }

                                    &::after {
                                        top: calc(50% + 3px);
                                        transform: skew(-45deg);
                                    }
                                }

                                @include media-pc {
                                    display: unset;
                                    padding: unset;
                                    // text-decoration: underline;
                                }
                            }

                        }

                        // コンテンツ
                        .siteGNav-megaMenu-contents {

                            // リスト
                            .siteGNav-megaMenu-list {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;

                                .siteGNav-megaMenu-listItem {
                                    width: 100%;
                                    text-align: center;
                                    border-top: 1px solid rgba($site-bese-color-black, .15);

                                    @include media-pc {
                                        width: calc(100% / 3);
                                        min-width: 240px;
                                        padding: .5rem 2rem;
                                        border-top: unset;
                                        text-align: unset;
                                    }

                                    .siteGNav-megaMenu-listItemLink {
                                        position: relative;
                                        display: block;
                                        padding: .5rem .25rem;
                                        color: $site-bese-color-black;
                                        font-weight: bold;
                                        transition: color .3s ease-in-out;
                                        text-decoration: none;

                                        @include media-pc {
                                            display: unset;
                                            padding: unset;
                                            white-space: nowrap;
                                        }

                                        &:hover {
                                            background-color: lighten($site-key-color, 50%);
                                            color: $site-key-color;

                                            @include media-pc {
                                                background-color: unset;
                                            }
                                        }

                                        &::before,
                                        &::after {
                                            content: unset;

                                            @include media-pc {
                                                content: "";
                                                position: absolute;
                                                left: -13px;
                                                display: block;
                                                width: 2px;
                                                height: 6px;
                                                background-color: $site-key-color;
                                                transition: left .3s ease-in-out;
                                            }
                                        }

                                        &::before {
                                            top: calc(50% - 3px);
                                            transform: skew(45deg);
                                        }

                                        &::after {
                                            top: calc(50% + 3px);
                                            transform: skew(-45deg);
                                        }

                                        &:hover::before,
                                        &:hover::after {
                                            left: -10px;
                                        }

                                        .siteGNav-megaMenu-listItemLink-inner {
                                            position: relative;

                                            @include media-pc {
                                                position: unset;
                                            }

                                            &::before,
                                            &::after {
                                                content: "";
                                                position: absolute;
                                                left: -13px;
                                                display: block;
                                                width: 2px;
                                                height: 6px;
                                                background-color: $site-key-color;
                                                transition: left .3s ease-in-out;

                                                @include media-pc {
                                                    content: unset;
                                                }
                                            }

                                            &::before {
                                                top: calc(50% - 3px);
                                                transform: skew(45deg);
                                            }

                                            &::after {
                                                top: calc(50% + 3px);
                                                transform: skew(-45deg);
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}

.headerFixedSpace {
    padding-top: $site-header-height-sp;

    @include media-pc {
        padding-top: $site-header-height-pc;
    }
}

// フッターお問い合わせ

.footerContact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    @include media-pc {
        flex-direction: row;
    }

    .Contact-inner {
        position: relative;
        width: 100%;
        background: #fff;
        opacity: 0.8;
        margin: 2em auto;
        padding:2em;
        border:1px solid #c6d4c6;
        text-align: center;
    
        @include media-pc {
           width: 80%;
        }
    
        .Contact-ttl {
            font-weight: bold;
            color: $site-key-color;

            @include media-pc {
                font-size: 1.5rem;
             }
        }
    
        .Contact-fbox {
            display: block;
        
            @include media-pc {
                // display: flex;
                // align-items: center;
                // justify-content: space-around;
                
                p {
                    margin-top: 20px;
                }
            }
        }
    
        .Contact-tel {
            text-align: center;
            font-weight: bold;
            font-size: 2rem;
    
            span {
                font-size: 1rem;
                font-weight: normal;
            }
        }	
    }
}

.textRed {
    color: red;
    font-weight: bold;
}

.textbold {
    font-weight: bold;
}

// ---------------------------------------------------------------------------
// トップページ
// ---------------------------------------------------------------------------

// ブランディング
.topBranding {
    position: relative;
    width: 100%;

    @include media-pc {}
}


// ---------------------------------------------------------------------------
// キービジュアル
// ---------------------------------------------------------------------------

.keyVisual {
        position: relative;
        height: calc(60vh - $site-header-height-sp);
        min-height: 300px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media-pc {
            height: calc(100vh - $site-header-height-pc);
            min-height: 600px;
        }
        
        .keyVisual-text-wrap {
            position: absolute;
            inset: 0;
            z-index: 1;
            display: flex;
            align-items: center;
            .keyVisual-text {
                padding-left: 5%;
                color: #fff;
                font-weight: bold;
                font-size: 1.3rem;
                text-shadow: 0 0px 5px rgba(0, 0, 0, 0.5);

                @include media-pc {
                    font-size: 3rem;
                }
            }
        }

        video {
            display: block;
            // width: 100%;
            opacity: 0.7;
        }
}


// ---------------------------------------------------------------------------
// トピック
// ---------------------------------------------------------------------------

.newsWrap {
    background-color: $site-key-color;
    margin-bottom: 2rem;

    .news {
        text-align: center;

        @include media-pc {
            border: none;
        }

        .news-details {
            margin-bottom: 0;
            padding: 1.5rem 2rem;

            .news-text {
                color: #fff;
                font-weight: bold;
                font-size: 1rem;

                &:hover {
                    text-decoration: none;
                }

                @include media-pc {
                    font-size: 1.4rem;
                }
            }
        }
    }
}

// ---------------------------------------------------------------------------
// 新着情報
// ---------------------------------------------------------------------------

.news-list {
    overflow-y: auto;
    max-height: 400px;
	margin-top: 50px;
    margin-bottom: 2rem;
	padding: 15px;

    @include media-pc {
        overflow-y: visible;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .news-list-item {
        border-bottom: 1px solid #D5D5D5;

        .news-category {
            display: block;
            width: 140px;
            padding: .5rem 1rem;
            color: #fff;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            font-size: 0.9rem;

            &.news {
                background-color: #ca96df; 
             }

             &.important {
                background-color: #b83737;
             }
        }
    }

    .news-list-item-link {
        display: block;
        padding: .5rem 0;
        transition: .3s;
        color: inherit;
        text-decoration: none;

        @include media-pc {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
        }
    }

    .news-list-item-inner {
        display: inline-block;
        padding: 15px;
    }

    .news-list-date {
        display: inline-block;
        margin-right: 1rem;
        padding: .5rem 0 .5rem 1rem;
        font-weight: bold;
    }
}

// セクションタイトル
.sectionTitleTop {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction : column;
    margin-bottom: 2em;


    @include media-pc {
        font-size: 1.6rem;
    }

    &::before {
        content: '';
        position: absolute;
        display: inline-block;
        width: 1px;
        height: 20px;
        bottom: -30px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        background-color: $site-key-color;
        /*Lightning見出しデザイン設定のCSS対策*/
        top:unset;
        border: unset;
        margin-left: unset;
        margin-right:unset;
    }
}

.sectionBasic {
    position: relative;
    padding-top: 3rem;
    padding-bottom: 3rem;


    &.sectionInquiry {
        padding-top: 3rem;
        padding-bottom: 3rem;
        background-color: #f5f4f4;

        @include media-pc {
            padding-top: 6rem;
            padding-bottom: 6rem;
        }
    }

    &.sectionStudent {
        padding-top: 3rem;
        padding-bottom: 3rem;
        background: repeating-linear-gradient(-45deg, #fef4f4, #fef4f4 14px,#ffefef 14px, #fff 78.00001%);
        // background-image: linear-gradient(to bottom, #fcf5f5 0%, #fcf5f5 78%, #fff 78.00001%, #fff 100%);

        @include media-pc {
            padding-top: 6rem;
            padding-bottom: 6rem;
        }
    }

    &.sectionCampaign,
    &.sectionCar {
        @include media-pc {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
    }
}

.sectionTitle {
    position: relative;
    padding: 0.25em 0;
    margin-bottom: 20px;
    font-size: 1.2rem;

    @include media-pc {
        font-size: 1.6rem;
    }

    &::after  {
        content: "";
        display: block;
        height: 4px;
        // background: linear-gradient(to right, rgb(89, 214, 166), transparent);
        background: linear-gradient(to right, rgb(163, 51, 51), transparent);
    }
}

.iconColor {
    color: $site-key-color;
}

// カード（キャンペーン）
.cardBasic {
    $image-width: 500px;

    display: block;
    // max-width: $image-width;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(#000, 15%);
    text-align: center;
    text-decoration: none;
    color: $site-bese-color-black;

    @include media-pc {
        margin: unset;
    }

    .cardBasic-image {
        position: relative;
        left: calc(50% - $image-width / 2);
        display: block;
        width: $image-width;
        height: auto;
        transition: transform .5s;
    }

    &:hover .cardBasic-image {
        transform: scale(1.1);
    }

    .cardBasic-text {
        position: relative;
        z-index: 1;
        margin-bottom: 0;
        padding: 1.5rem;
        background-color: $site-key-color;
        font-weight: bold;
        transition: color .3s;

        .cardBasic-text-inner {
            position: relative;
            color: #fff;

            &::before,
            &::after {
                content: "";
                position: absolute;
                left: -13px;
                display: block;
                width: 2px;
                height: 6px;
                background-color: #fff;
                transition: left .3s ease-in-out;

            }

            &::before {
                top: calc(50% - 6px);
                transform: skew(45deg);
            }

            &::after {
                top: calc(50%);
                transform: skew(-45deg);
            }
        }
    }
}


.TopOverArea {
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;

    .campaignSlider {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        font-size: 0;
    }

    .campaignSlider-item {
        opacity: .75;
        transition: .5s;

        .campaignSlider-link {
            display: block;
            width: 100%;
            padding: 2rem;
            color: inherit;
            transition: .3s;
            text-decoration: none;

            .campaignSlider-imgWrap {
                overflow: hidden;
                width: 100%;

                .campaignSlider-img {
                    display: block;
                    width: 100%;
                    height: auto;
                    transition: .5s;
                    
                }
            }

            .campaignSlider-name {
                text-align: center;
                border: 1px solid #972f4e;
                padding: 10px;
                margin-top: 10px;
                color: #972f4e;
                font-size: 1rem;
                font-weight: bold;

                @include media-pc {
                    font-size: 1.2rem;
                }
            
            }
        }
    }

    .slick-center .campaignSlider-item {
        opacity: 1;
    }

}

// カード（車種）
.cardBasic02 {
    $image-width: 500px;
    display: block;
    max-width: $image-width;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(#000, 15%);
    text-align: center;
    text-decoration: none;
    color: $site-bese-color-black;

    @include media-pc {
        margin: unset;
    }

    &:hover {
        color: $site-key-color;
    }

    .cardBasic02-image {
        position: relative;
        left: calc(50% - $image-width / 2);
        display: block;
        width: $image-width;
        height: auto;
        transition: transform .5s;
    }

    &:hover .cardBasic02-image {
        transform: scale(1.1);
    }

    .cardBasic02-text {
        position: relative;
        z-index: 1;
        margin-bottom: 0;
        padding: 1rem 1rem;
        background-color: #fff;
        font-weight: bold;
        transition: color .3s;

        .cardBasic02-text-inner {
            position: relative;

            &::before,
            &::after {
                content: "";
                position: absolute;
                left: -13px;
                display: block;
                width: 2px;
                height: 6px;
                background-color: $site-key-color;
                transition: left .3s ease-in-out;

            }

            &::before {
                top: calc(50% - 6px);
                transform: skew(45deg);
            }

            &::after {
                top: calc(50%);
                transform: skew(-45deg);
            }
        }
    }
}

.carSlider {
    .carSlider-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.footer {
    background-color: #f5f4f4;

    a {
        color: $site-bese-color-black;
        transition: color .3s;
        text-decoration: none;

        &:hover {
            color: $site-key-color;
        }
    }

    .footerSitemap-title {
        font-weight: bold;
    }

    .footerSitemap-list {
        list-style: none;
        margin: 0;
        padding: 0;

        .footerSitemap-listItem {
            margin-bottom: .5rem;
            font-weight: bold;
        }
    }

}

.pageTitle {
    position: relative;
    height: 30vh;
    min-height: 180px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include media-pc {
        height: 450px;
    }

    .pageTitle-inner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(rgb(25, 90, 128), 35%);

        .pageTitle-text {
            color: #fff;
            font-weight: bold;

            @include media-pc {
                font-size: 2.8rem;
            }

        }
    }
}

.pageTitleNoBG {
    position: relative;
    background: repeating-linear-gradient(-45deg, #fef4f4, #fef4f4 14px,#ffefef 14px, #fff 78.00001%);
    min-height: 160px;
    background-color: #f9f9f9;

    .pageTitleNoBG-inner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .pageTitleNoBG-text {
            color: $site-key-color;
            font-weight: bold;

            @include media-pc {
                font-size: 2.6rem;
            }

        }
    }
}

.breadCrumb {
    background-color: #818385;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin: 0;
    }

    .breadCrumbList {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;

        .breadCrumbList-item {
            padding-top: .5rem;
            padding-bottom: .5rem;
            font-size: .75rem;
            line-height: 1;

            @include media-pc {
                font-size: 1rem;
            }

            &:not(:first-child) {
                position: relative;
                padding-left: 1.5rem;

                ::before {
                    content: "";
                    position: absolute;
                    top: 10px;
                    bottom: 50%;
                    left: .75rem;
                    display: block;
                    width: 1px;
                    background-color: #fff;
                    transform: skewX(45deg);
                }

                ::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    bottom: 10px;
                    left: .75rem;
                    display: block;
                    width: 1px;
                    background-color: #fff;
                    transform: skewX(-45deg);
                }
            }
        }

        .breadCrumbList-link {
            color: $site-bese-color-black;
            text-decoration: none;
            color: #fff;

            &:hover[href] {
                text-decoration: underline;
            }
        }
    }

}

.wokerVoice {

    .wokerVoice-title {
        position: relative;
        display: inline-block;
        margin-bottom: 0;
        padding: .5rem 3rem .5rem 4rem;
        border-radius: 3px 0 0 0;
        background-color: $site-key-color;
        color: #fff;
        font-size: 1.2rem;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 100%;
            background-color: $site-key-color;
            width: 10px;
            height: 100%;
            border-radius: 0 3px 0 0;
        }

        &::after {
            content: "";
            position: absolute;
            top: 1px;
            bottom: 0;
            left: calc(100%);
            background-color: $site-key-color;
            display: block;
            width: 20px;
            border-radius: 0 3px 0 0;
            transform: skewX(25deg);
        }
    }

    .wokerVoice-inner {
        border: 1px solid $site-key-color;
        border-radius: 0 3px 3px 3px;
        display: flex;
        gap: 1.5rem;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem;

        @include media-pc {
            flex-direction: row;
        }
    }

    .wokerVoice-icon {
        overflow: hidden;
        width: 150px;
        min-width: 150px;
        height: 150px;
        margin-bottom: 1rem;
        background-color: rgb(194, 182, 196);
        border: 1px solid rgb(194, 182, 196);
        border-radius: 50%;

        @include media-pc {
            width: 100px;
            min-width: 100px;
            height: 100px;
        }

        .wokerVoice-img {
            display: block;
            width: 150px;
            height: auto;
            margin-top: 10px;
            transform: scale(.85);

            @include media-pc {
                width: 100px;
            }
        }
    }

    .wokerVoice-name {
        margin-bottom: 0;
        font-size: .8rem;
        font-weight: bold;
        text-align: center;

    }
}

// ---------------------------------------------------------------------------
// 入校のご案内
// ---------------------------------------------------------------------------

.tab_container {
    padding-bottom: 1em;
    background-color: #fff;
    margin: 0 auto;

    .tab_item {
        width: calc(100%/2); 
        padding: 15px 0;
        background-color: #ececec;
        text-align: center;
        color: $site-key-color;
        display: block;
        float: left;
        text-align: center;
        font-weight: bold;
        transition: all 0.2s ease;
        cursor: pointer;
        border: 1px solid #fff;

        &:hover{
            opacity: 0.75;
        }
      }

      input[name="tab_item"] {
        display: none;
      }

      .tab_content {
        display: none;
        padding: 1em 1em 0;
        clear: both;
        overflow: hidden;
      }

      #tab1:checked ~ #tab1_content,
      #tab2:checked ~ #tab2_content,
      #tab3:checked ~ #tab3_content,
      #tab4:checked ~ #tab4_content {
        display: block;
      }

      input:checked + .tab_item {
        background-color: $site-key-color;
        color: #fff;
      }
}

.dotlist {

    .dotlist-ttl {
        font-size: 1.1rem;
        color: $site-key-color;
        font-weight: bold;
    }

    ul {
        border: 3px solid #ffefef;
        background: repeating-linear-gradient(-45deg, #fef4f4, #fef4f4 3px,#ffefef 3px, #ffefef 7px);
        padding: 0.5em 0.5em 0.5em 0.5em;
        margin: 2em 0;
        list-style: none;

        li {
            position:relative;
            line-height: 1.5;
            padding: 0.3em 0 0.3em 1.6em;

            &::before {
                content: '';
                position: absolute;
                background-color: #ee8992;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                left: 10px;
                top: 13px;
            }
        }
    }

    .face-photo {
        padding: 20px;
        border:1px solid  #a97bbb;

        .face-photo-ttl {
            color: #a97bbb;
            font-weight: bold;
            text-align: center;
            font-size: 1.1rem;

            @include media-pc {
                text-align: left;
            }
        }
    }
}
.credit_card {
    display: flex;
    width: auto;
    flex-wrap: wrap;

    @include media-pc {
        width: 50%;
    }
}

.credit_card_List {
    width: 25%;
    flex: 1 1 auto;
    padding: 1rem;
    list-style: none;
    display: flex;
    justify-content: center;
}

.credit_card_List img {
    width: 100%;
    max-width: 150px; /* 画像の最大幅を設定 */
    height: auto; /* 画像の縦横比を維持 */
}


:root {
    --flowchart-size: 40px;/* 番号のサイズ 変更可 */
    --flowchart-border: 5px;/* 縦線太さ 変更可 */
}

.flowchart {
    list-style: none !important;
    counter-reset: flownum;/* リストアイテムの名称(任意) */
    margin: 1em 0;/* フローチャートと前後要素との距離(任意) */
    padding: 0 !important;

    .flowchart-inner{
        position: relative;
        padding: 0 0 2em calc(var(--flowchart-size) + 8px);/* 8px がタイトルとの距離であり余白 */

        &::before {
            content: counter(flownum);
            counter-increment: flownum;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -5px;/* 通常は 0, 横に並ぶタイトルとのバランスを見て調整 */
            left: 0;
            z-index: 2;
            width: var(--flowchart-size);
            height: var(--flowchart-size);
            border-radius: 50%;/* 番号円形, 四角形で良ければこの一行削除 */
            background: #ee8992;/* 番号背景 */
            color: white;/* 番号文字色 */
            font-size: 1.4rem;
            font-weight: bold;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: calc(var(--flowchart-size) / 2 - calc(var(--flowchart-border) / 2));
            z-index: 1;
            width: var(--flowchart-border);
            height: 100%;
            background: rgb(200,200,200);/* 線の色 */
        }
        
    }

    .flowchart-title {
        margin: 0 0 .6em;
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 1.4;

        @include media-pc {
            font-size: 1.4rem;
        }
    }
}

/* 最終工程の縦線が必要な場合はここから削除 */
.flowchart .flowchart-inner:last-of-type::after {
content: none;
}
/* 最終工程の縦線が必要な場合はここまで削除 */

/* p要素を利用した場合の調整 */
.flowchart .flowchart-inner p {
margin: 1em 0 !important;
}
.flowchart .flowchart-inner p:last-of-type {
margin-bottom: 0 !important;
}

.disco {
    padding: 1rem;
    margin-top: 20px;
    text-align: center;

    .disco-text {
        position: relative;
        background-color: lighten($site-key-color, 55%);
        box-shadow: 0px 0px 0px 5px lighten($site-key-color, 55%);
        border: dashed 2px white;
        padding: 1em 0.5em;
        color: #972f4e;
        font-size: 1.4rem;
        font-weight: bold;
    
        &:after{
            position: absolute;
            content: '';
            right: -7px;
            top: -7px;
            border-width: 0 15px 15px 0;
            border-style: solid;
            border-color: #972f4e #fff #972f4e;
            box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15);
        }
    
        p {
            margin: 0;
            padding: 0;
        }

        span {
            font-size: 1.1rem;
        }
    
    }

    .disco-caution {
        text-align: left;
    }
}

// ---------------------------------------------------------------------------
// よくある質問
// ---------------------------------------------------------------------------

.qa-list {
	margin-bottom: 80px;

    dl {
        position: relative;
        margin: 0;
        padding: 28px 80px 28px 30px;
        cursor: pointer;
        border-bottom: 1px solid rgb(153, 152, 152);
        
        &:first-child {
            border-top: 1px solid rgb(153, 152, 152);
        }

        &:before {
            position: absolute;
            top: 35px;
            right: 35px;
            display: block;
            width: 7px;
            height: 7px;
            margin: auto;
            content: '';
            transform: rotate(135deg);
            border-top: 2px solid #000;
            border-right: 2px solid #000;
        }

        dt {
            position: relative;
            margin: 0;
            padding: 0 0 0 50px;
            font-weight: bold;

            &:before {
                font-size: 22px;
                line-height: 1;
                position: absolute;
                top: 3px;
                left: 0;
                display: block;
                content: 'Q.';
                color: lighten($site-key-color, 10%);;
            }
        }

        dd {
            position: relative;
            display: none;
            height: auto;
            margin: 20px 0 0;
            padding: 0 0 0 50px;

            &:before {
                font-size: 22px;
                line-height: 1;
                position: absolute;
                top: 3px;
                left: 2px;
                display: block;
                content: 'A.';
                font-weight: bold;
                color: #a97bbb;

                p {
                    margin: 30px 0 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .open {

        &:before {
            transform: rotate(-45deg);
        }
    }
}

// ---------------------------------------------------------------------------
// 教習プラン
// ---------------------------------------------------------------------------

.flow-item {
    padding-top: 30px;
    padding-left: 0;
	
    @include media-pc {
        width: 700px;
        margin: 0 auto;
    }

    li {
        position: relative;
        list-style: none;
        font-size: 1.2rem;
        color: #fff;
    
        @include media-pc {
            font-size: 1.3rem;
        }

        .flow-clear {
            background-color: #ff8c00;
        }

      }
      
  }

  .flow-item > li:not(:last-child) {
	margin-bottom: 40px;
  }


  .flow-item > li dl {
	width: 100%;
	padding: 20px 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    background-color: $site-key-color;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 10px;
	position: relative;
  }

  .flow-item > li:not(:last-child) dl::before,
  .flow-item > li:not(:last-child) dl::after {
	content: "";
	border: solid transparent;
	position: absolute;
	top: 100%;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
  }

  .flow-item > li:not(:last-child) dl::before {
	border-width: 22px;
	border-top-color: $site-key-color;
  }

  .flow-item > li:not(:last-child) dl::after {
	border-width: 20px;
	border-top-color: $site-key-color;
  }

// ---------------------------------------------------------------------------
// 各種講習
// ---------------------------------------------------------------------------

// アンカーリンク

.Anchor {
    padding-top: 65px;
    margin-top:-65px;
}

.pageAnchorNav {
    margin-top: .25rem;
    margin-bottom: .25rem;
    overflow-y: auto;

    @include media-pc {
        justify-content: center;
    }

    .pageAnchorNav-list {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        list-style: none;
    
        @include media-pc {
            justify-content: center;
        }

        .pageAnchorNav-list-item {
            min-width: 60%;
            text-align: center;
            padding-right: 1rem;
        
            
            @include media-pc {
                min-width: auto;
                padding-bottom: 0.5rem;
            }

            .pageAnchorNav-list-item-inner {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                row-gap: 0.25rem;
                border-radius: 5px;
                padding: 0.5rem;
                background-color: rgba(33, 37, 41, 0.05);
                text-decoration: none;
                color: #212529;
                transition: background-color 0.3s;
                transition: color 0.3s;
            
                &:hover {
                    background-color: rgba(243, 180, 217, 0.15);
                    color: $site-key-color;
                }
            
                @include media-pc {
                    min-width: 100px;
                    padding: 1rem;
                }
            
            }
        
        }
    
    }

}


.colorBG {
    background-color: lighten($site-key-color, 63%);
    padding: 1rem;
    margin-top: 20px;

    .Point-title{
        background-color: #fff;
        padding: 15px 0;
        text-align: center;
        font-size: 1.1rem;
        font-weight: bold;
    }
}

// ---------------------------------------------------------------------------
// アクセス
// ---------------------------------------------------------------------------

.alternating-imgright-box {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 2rem;

    @include media-pc {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .imgtxt {
        padding-right: 0;

        @include media-pc {
            width: 560px;
            padding-right: 10px;
        }

        .aaa {
            position: relative;
            padding: 0 .5em .5em 1.7em;
            border-bottom: 1px solid #ff5a5f;

            &:after {
                position: absolute;
                top: .4em;
                left: .4em;
                content: '';
                width: 12px;
                height: 12px;
                background-color: #ff5a5f;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                z-index: 2;
            }
        }
    }
}

.alternating-imgleft-box {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 2rem;

    @include media-pc {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }

    .imgtxt {
        padding-left: 0;

        @include media-pc {
            width: 560px;
            padding-left: 10px;
        }
    }
}

.title-box2 {
    margin: 1em 0;
    padding: 1.5em 1em 1em;
    position: relative;
    border: 2px solid #a97bbb;
    border-radius: 8px;
    font-weight: bold;

    .title-box2-title{
        position: absolute;
        top: -0.8em;
        left: 20px;
        font-size: 1.1em;
        background-color: #a97bbb;
        border-radius: 5px;
        color: #fff;
        padding: 0.2em 0.5em;
      }

      p {
        margin: 0;
        padding: 0;
      }

      ul {
        padding-top: 10px;
        line-height: 1.8;
        font-weight: bold;
      }

  }

// ---------------------------------------------------------------------------
// お知らせ
// ---------------------------------------------------------------------------

.tab_container02 {
    padding-bottom: 1em;
    background-color: #fff;
    margin: 0 auto;

    .tab_item {
        width: calc(100%/6); 
        padding: 15px 0;
        background-color: #ececec;
        text-align: center;
        color: $site-key-color;
        display: block;
        float: left;
        text-align: center;
        font-weight: bold;
        transition: all 0.2s ease;
        cursor: pointer;
        border: 1px solid #fff;

        &:hover{
            opacity: 0.75;
        }
      }

      input[name="tab_item"] {
        display: none;
      }

      .tab_content {
        display: none;
        padding: 1em 1em 0;
        clear: both;
        overflow: hidden;
      }

      #tab1:checked ~ #tab1_content,
      #tab2:checked ~ #tab2_content,
      #tab3:checked ~ #tab3_content,
      #tab4:checked ~ #tab4_content {
        display: block;
      }

      input:checked + .tab_item {
        background-color: $site-key-color;
        color: #fff;
      }
}

// .news-list02 {
// 	margin-top: 50px;
//     margin-bottom: 2rem;
// 	padding: 15px;

//     @include media-pc {
//         overflow-y: visible;
//     }

//     a {
//         text-decoration: underline;

//         &:hover {
//             text-decoration: none;
//         }
//     }

//     .news-list-item {
//         border-bottom: 1px solid #D5D5D5;

//         .news-category {
//             display: block;
//             width: 140px;
//             padding: .5rem 1rem;
//             color: #fff;
//             line-height: 1;
//             text-align: center;
//             white-space: nowrap;
//             font-size: 0.9rem;

//             &.news {
//                 background-color: #a97bbb; 
//              }

//              &.important {
//                 background-color: #b83737;
//              }
//         }
//     }

//     .news-list-item-link {
//         display: block;
//         padding: .5rem 0;
//         transition: .3s;
//         color: inherit;
//         text-decoration: none;

//         @include media-pc {
//             display: flex;
//             flex-direction: row;
//             flex-wrap: nowrap;
//             align-items: center;
//         }
//     }

//     .news-list-item-inner {
//         display: inline-block;
//         padding: 15px;
//     }

//     .news-list-date {
//         display: inline-block;
//         margin-right: 1rem;
//         padding: .5rem 0 .5rem 1rem;
//         font-weight: bold;
//     }
// }

// ---------------------------------------------------------------------------
// お知らせ詳細
// ---------------------------------------------------------------------------

.news-content {

    .news-list-item-inner {
        display: inline-block;
        // padding: 15px;
        margin-bottom: 20px;
    }

    .news-list-date {
        display: inline-block;
        margin-right: 1rem;
        // padding: .5rem 0 .5rem 1rem;
        font-weight: bold;
    }

    .news-category {
        display: block;
        width: 140px;
        padding: .5rem 1rem;
        color: #fff;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        font-size: 0.9rem;

        &.news {
            background-color: #ca96df; 
         }

         &.important {
            background-color: #b83737;
         }
    }

    p {
        line-height: 1.8;
    }

}

// ---------------------------------------------------------------------------
// 採用情報
// ---------------------------------------------------------------------------

.box {
    position: relative;
    margin-bottom: 80px;

    &:before,
    &:after {
    content:"";
    position: absolute;
    width: 100%;
    height: 8px;
    background: linear-gradient( -45deg, #fff 25%, #fbc6cc 25%,#fbc6cc 50%, #fff 50%, #fff 75%, #fbc6cc 75%, #d8e0ff);
    background-size: 8px 8px;
  }

  h3 {
    margin: 0;

    &:first-letter{
        font-size: 1.4em;
        color: #0094D6;
      }

  }

    .box_inner {
        padding: .5em;
        padding-top: 50px;
        padding-bottom: 20px;
      }

  }
  

/*吹き出し*/
.balloon_l,
.balloon_r {
    margin: 30px 0;
    display:flex;
    justify-content: flex-start;
    align-items: flex-start; /*縦位置を上揃え*/

    img {
        width: 100px; /*任意のサイズ*/
        height: auto;
    }

    .says {
        max-width: 700px; /*最大幅は任意*/
        display: flex;
        flex-wrap: wrap;
        position: relative;
        padding: 17px 13px 15px 18px;
        border-radius: 12px;
        background: repeating-linear-gradient(-45deg, #fef4f4, #fef4f4 3px, #ffefef 3px, #ffefef 7px);
        box-sizing:border-box;
        margin:0 !important;
        line-height:1.5;

        p {
            margin:8px 0 0 !important; 
        }
        p:first-child {
            margin-top:0 !important;
        }

        &:after {
            content: "";
            position: absolute;
            border: 10px solid transparent;
        }

      }
}

.balloon_l {
  
    .faceicon {
        margin-right:25px;
    }

    .says {
        
        &:after {
            left: -26px;
            border-right: 22px solid #ffefef;
          }
      }
}

.balloon_r {
    justify-content:flex-end;

  .faceicon {
    margin-left:25px;
    order:2 !important;
  }

  .says {
        
    &:after {
        right: -26px;
        border-left: 22px solid #ffefef;
      }
  }
  
}

// .heading04 {
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	font-size: 1.5rem;
// 	text-align: center;
// 	color: $site-key-color;
// 	font-weight: bold;
// 	margin-top: 50px;

//     @include media-pc {
//         margin-top: 0;
//         font-size: 1.8rem;
//     }

//     &:before,
//     &:after {
//         content: '';
//         width: 3px;
//         height: 40px;
//         background-color: $site-key-color;
//     }

//     &:before {
//         margin-right: 30px;
//         transform: rotate(-35deg)
//     }

//     &:after {
//         margin-left: 30px;
// 	    transform: rotate(35deg)
//     }
    
// }

// ---------------------------------------------------------------------------
// 関連項目
// ---------------------------------------------------------------------------

.button-text-box {
    width: 100%;
    max-width: 940px;
    margin: 0 auto;
	border: solid 1px #ee8992;
	margin: 40px 0;
	padding: 2rem;

    @include media-pc {
        margin: 0 auto;
    }

    p {
        text-align: center;
    }
}

// ---------------------------------------------------------------------------
// お問い合わせ
// ---------------------------------------------------------------------------

.contact-box {
    margin: 2em auto;
    padding: 1em;/*内側余白*/
    border: dotted 3px #e2c2b3;
    text-align: center;

    @include media-pc {
		width: 60%;
		margin: 0 auto;
    }

    .contact-tel {
        margin-bottom: 0;
        font-size: 2.5rem;
    
        a {
            color: $site-key-color;
        }
    }
}

// ---------------------------------------------------------------------------
// このサイトについて
// ---------------------------------------------------------------------------

.privacyList {
    line-height: 2;
}





// TOP_ボタン

.BasicBtn {
    display: inline-block;
    width: 100%;
    max-width: 240px;
    margin: 1rem;
    padding: 0.8em;
    text-decoration: none;
    color: #fff;
    background-color: #383f45;
    transition: .3s;

    &:hover {
        background-color: lighten(#383f45, 10%);
        color: #fff;
    }
}

// 画像左の配置
.imgleft-box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 2rem;

    @include media-pc {
        flex-direction: row;
    }

    p {
        padding-left: 0;

        @include media-pc {
            padding-left: 30px;
        }
    }
}

.emphasis-title {
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;

    @include media-pc {
        font-size: 2.2rem;
    }
}

.emphasis-text {
    margin: 0;
    padding: 20px;
    text-align: center;
    font-weight: bold;
}

// ---------------------------------------------------------------------------
// ページトップ
// ---------------------------------------------------------------------------

.siteFooter-pageTop {
    position: fixed;
    z-index: 999;
    bottom: 1rem;
    right: 1rem;

    @include media-pc {
        bottom: 4rem;
        right: 1.5rem;
    }

    a {
        color: #fff;
    }

    .pageTop-link {
        display: block;
        padding: .5rem 1rem;
        background-color: $site-key-color;
        font-size: 1rem;
        text-decoration: none;
        cursor: pointer;
        transition: .3s;

        &:hover {
            padding: .25rem 1rem .75rem;
            background-color: lighten($site-key-color, 10%);
            color: #fff!important;
        }
    }
}

// ミニタイトル
.mini-ttl {
    overflow: hidden;
    padding-top: 2rem;
    text-align: center;
    font-size: 1.2rem;

        .mini-ttlInner {
            position: relative;
            display: inline-block;
            font-weight: bold;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: calc(50% - 1px);
            display: block;
            width: 100vw;
            height: 1px;
            background-color: $site-key-color;
        }
        &::before {
            right: calc(100% + 2rem);
        }
        &::after {
            left: calc(100% + 2rem);
        }
    }
}



// Googleマップ

iframe {
    width: 100%;
}

// 電話番号リンクさせない

.PrivacypolicyTel {
    pointer-events: none;
    text-decoration: none;
    color: $site-key-color;
}



// ---------------------------------------------------------------------------


// ---------------------------------------------------------------------------